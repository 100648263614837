<template>
  <div class="SoftwareActivationInfo">

  <!--我的软件功能区域-->
  <div class="info-title">List of Software Feature</div>
  <el-table :data="memberSoftFeatureList" class="SoftwareActivationInfo-table" style="width: 100%; margin-bottom: 24px;">
    <el-table-column label="Software"  prop="softappName"> </el-table-column>
    <el-table-column label="Feature"  prop="featureName"> </el-table-column>
    <el-table-column label="Activation Date" prop="ctime"> 
      <template slot-scope="scope">
          {{toUTCDate(scope.row.ctime)}}
      </template>  
    </el-table-column>     
    <el-table-column label="Expiration Date"  prop="dueTime">
      <template slot-scope="scope">
          {{toUTCDate(scope.row.dueTime)}}
      </template>  
    </el-table-column>        
  </el-table>   

  <!--我的授权电脑区域-->
  <div class="info-title">List of Bound Computers</div>
  <el-table :data="memberMacRecordList" class="SoftwareActivationInfo-table" style="width: 100%; margin-bottom: 24px;">
    <el-table-column label="Software"  prop="softappName" width="150"> </el-table-column>
    <el-table-column label="Computer Name"  prop="machineName" width="150"> </el-table-column>
    <el-table-column label="Computer ID"  prop="machineNo" width="350"  align="center"> </el-table-column>
    <el-table-column label="Bind Time" prop="utime" width="200"  align="center"> 
      <template slot-scope="scope">
          {{toUTCDate(scope.row.utime)}}
      </template> 
    </el-table-column>     
    <el-table-column align="center">
      <template slot-scope="scope">
        <el-button type="primary" @click="delMacRecord(scope.row.id)">Unbind</el-button>    
      </template>
    </el-table-column>     
  </el-table>  

  <!--已使用激活码区域-->
  <div class="info-title">List of Activation Codes in Use</div>
  <el-table :data="authorizationOrderList" class="SoftwareActivationInfo-table" style="width: 100%; margin-bottom: 24px;">
    <el-table-column label="Activation Code"  prop="authorizationCode"> </el-table-column>
    <el-table-column label="Activation Date" prop="utime"> 
      <template slot-scope="scope">
          {{toUTCDate(scope.row.utime)}}
      </template> 
    </el-table-column> 
    <el-table-column width="200">
      <template slot-scope="scope">
        <el-button type="primary" @click="checkInfo(scope.row)">Check</el-button>    
      </template>
    </el-table-column>         
  </el-table>  

  <el-dialog  :visible.sync="orderFeatureDialog" class="order-feature-dialog">
        <div>
          <el-table :data="orderFeatureList" class="SoftwareActivationInfo-table" style="width: 100%; margin-bottom: 24px;">
            <el-table-column label="Feature"  prop="featureName"> </el-table-column>
            <el-table-column label="Days" prop="years"> </el-table-column>    
          </el-table>  
          
        </div>
    </el-dialog>

  </div>  
</template>

<script>
import {
  listMemberSoftFeatureHttp,
  listAuthorizationOrderHttp,
  listMemberMacRecordHttp,
  delMemberMacRecordHttp
} from "@/api/getData";
import { strToUTCDate} from '@/utils/tool'
export default {
name: 'SoftwareActivationInfo',
data() {
  return {
    memberSoftFeatureList:[],
    authorizationOrderList:[],
    memberMacRecordList:[],
    orderFeatureDialog: false,
    softappId:'',
    orderFeatureList:[],
  }
},
mounted() {
  this.softappId = this.$route.query.id
  this.listMemberSoftFeature()
  this.listAuthorizationOrder()
  this.listMemberMacRecord()
},
methods:{
  //查看激活码的功能信息
  checkInfo(rowData){
     this.orderFeatureList = JSON.parse(rowData.featureJson)
     this.orderFeatureDialog  = true
  },
  toUTCDate(str){
    return strToUTCDate(str)
  },
  //删除授权电脑
  delMacRecord(rowDataId){
    this.$confirm('Confirm Unbind ?')
    .then(_ => {
      delMemberMacRecordHttp(rowDataId).then((data)=>{
        this.listMemberMacRecord()
        this.$message.success("Successful")                
      })
      
    })
    .catch(_ => {});
  },
  //获取软件功能列表
  listMemberSoftFeature(){
    listMemberSoftFeatureHttp(this.gMemberId,this.softappId).then(({data})=>{
        this.memberSoftFeatureList = data
    })
  },
  //获取激活码订单列表
  listAuthorizationOrder(){
    listAuthorizationOrderHttp(this.gMemberId,this.softappId).then(({data})=>{
        this.authorizationOrderList = data
    })
  },
  //获取授权电脑列表
  listMemberMacRecord(){
    listMemberMacRecordHttp(this.gMemberId,this.softappId).then(({data})=>{
        this.memberMacRecordList = data
    })
  },
}  

}
</script>

<style lang="scss">
.SoftwareActivationInfo {
padding-top: 0;
color: #282828;
line-height: 1;
.order-feature-dialog{
  .el-dialog__header{
    background-color: #FFF;
  }
}
.info-title{
  font-size: 1.04vw;
  font-weight: bold;
  line-height: 50px;
  color: #C81432;
}
.SoftwareActivationInfo-table {
  /* 其他样式 */
  /* 设置外边框为圆角 */
  .el-table__body,
  .el-table__header-wrapper {
    border-radius: 10px; /* 根据需要调整圆角大小 */
  }

  .el-table__body .cell {
       font-size: 0.72vw;
       color: #666666;
  }
  .el-table__header .cell {
       font-size: 0.93vw;
       color: #282828;
  }
  .el-button {
    width: 100px;
    height: 36px;
    border-radius: 8px;
    opacity: 1;
    background: #b90025;
    font-size: 0.72vw;
    font-weight: normal;
    span {
      color: #ffffff;
    }
  } 
}
}
</style>
